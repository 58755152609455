<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1000" :centered="true"  :closable="false">
      <template slot="footer">
        <a-button @click="cancelOrder">返回</a-button>
        <a-button :disabled="showType=='detail'||(detailData&&detailData.status==3)||isDisabled" type="primary" @click="confirmOrder">提交</a-button>
      </template>
      <h3 style="text-align:center;font-size: 20px;font-weight: bold">品质改进单</h3>
      <a-form-model ref="formData" :model="formData" :rules="formDataRules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" layout="inline" class="query-form">
        <div style="padding: 10px 0px 10px 0px;border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: 0px">
          <a-form-model-item label="项目名称" prop="monitorpointnum" style="width: 48%">
            <a-select v-if="showType=='edit'||showType=='add'" v-model="formData.monitorpointnum" placeholder="请选择" :disabled="true">
              <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
            </a-select>
            <a-input v-if="showType=='detail'"  style="width: 200px;" v-model="formData.monitorpointname" :disabled="showType=='detail'||showType=='edit'"></a-input>
          </a-form-model-item>
          <a-form-model-item label="品质改进单号" prop="improveordernum" style="width: 48%">
            <a-input placeholder="系统自动生成" style="" v-model="formData.improveordernum" :disabled="true"></a-input>
          </a-form-model-item>
        </div>
        <div style="padding: 10px 0px 10px 0px;border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -3px">
          <a-form-model-item label="情况描述" style="width: 38%;margin-left: -0.7%" :label-col="{ span: 8 }"></a-form-model-item>
          <a-form-model-item label="接收人" prop="receiver" style="width: 28.5%">
            <a-select v-model="formData.receiver" placeholder="" :disabled="true" style="width: 220px;" :dropdownMatchSelectWidth=false>
              <a-select-option v-for="(item, index) in userList" :key="index" :value="item.userid">{{item.username}}</a-select-option>
            </a-select>
<!--            <a-input v-model="formData.monitorpointname"  placeholder="请输入" :disabled="true"></a-input>-->
          </a-form-model-item>
          <a-form-model-item label="日期" prop="orderstime" style="width: 28%;">
            <a-date-picker show-time placeholder="选择时间" v-model="formData.orderstime" :disabled="true"/>
          </a-form-model-item>
          <a-form-model-item label="" prop="description" style="margin-left: 5%;width: 100%" :wrapper-col="{ span: 22 }">
            <a-textarea v-model="formData.description" placeholder="请督导员填写情况描述" cols="150" rows="3" :disabled="true"></a-textarea>
          </a-form-model-item>
          <a-form-model-item label="上传附件" prop="superviseupload" style="width: 50%">
            <a-upload
                :disabled="showType=='detail'"
                style="width: 220px"
                name="fileList"
                :headers="uploadHeaders"
                :data="supervise"
                action="/upload"
                :file-list="superviseUploadList"
                @change="superviseUploaded">
              <a-button :disabled="true"> <a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
        </div>
        <a-divider style="font-weight: bold;margin-bottom: 15px">项目填写内容</a-divider>
        <div style="padding: 10px 0px 10px 0px;border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -3px">
          <a-form-model-item label="原因分析" style="width: 48%;"></a-form-model-item>
          <a-form-model-item label="责任人" prop="responsible" style="width: 22%">
            <a-input v-model="formData.responsible" placeholder="请输入" :disabled="showType=='detail'||(detailData&&detailData.status==3)"></a-input>
          </a-form-model-item>
          <a-form-model-item label="日期" prop="analysistime" style="width: 22%;margin-left: -2.5%">
            <a-date-picker show-time placeholder="选择时间" v-model="formData.analysistime" :disabled="showType=='detail'||(detailData&&detailData.status==3)" @change="calcProcessTime(1)"/>
          </a-form-model-item>
          <a-form-model-item label="" prop="causeanalysis" style="margin-left: 5%;width: 100%" :wrapper-col="{ span: 22 }">
            <a-textarea v-model="formData.causeanalysis" placeholder="请项目填写原因分析" cols="150" rows="3" :disabled="showType=='detail'||(detailData&&detailData.status==3)"></a-textarea>
          </a-form-model-item>
        </div>
        <div style="padding: 10px 0px 10px 0px;border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -3px">
          <a-form-model-item label="改进措施" style="width: 10%;;margin-left: 3%" :label-col="{ span: 22 }"></a-form-model-item>
          <a-form-model-item label="批准人" prop="approver" style="width: 21%;margin-left: 2%">
            <a-input v-model="formData.approver" placeholder="请输入" :disabled="showType=='detail'||(detailData&&detailData.status==3)"></a-input>
          </a-form-model-item>
          <a-form-model-item label="日期" prop="improvetime" style="width: 29%;margin-left: -3%">
            <a-date-picker show-time placeholder="选择时间" v-model="formData.improvetime" :disabled="showType=='detail'||(detailData&&detailData.status==3)" @change="calcProcessTime(2)"/>
          </a-form-model-item>
          <a-form-model-item label="计划完成时间" prop="scheduledtime" style="width: 29%;margin-left: -3%;" :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }">
            <a-date-picker show-time placeholder="选择时间" v-model="formData.scheduledtime" :disabled="showType=='detail'||(detailData&&detailData.status==3)"/>
          </a-form-model-item>
          <a-form-model-item label="" prop="improvemeasures" style="margin-left: 5%;width: 100%" :wrapper-col="{ span: 22 }">
            <a-textarea v-model="formData.improvemeasures" placeholder="请项目填写改进措施" cols="150" rows="3" :disabled="showType=='detail'||(detailData&&detailData.status==3)"></a-textarea>
          </a-form-model-item>
        </div>
        <div style="padding: 10px 0px 10px 0px;border-style:solid solid solid solid;border-color:skyblue; border-width: 3px;margin-top: -3px">
          <a-form-model-item label="执行及其验证情况" style="width: 48%;" :label-col="{ span: 9 }"></a-form-model-item>
          <a-form-model-item label="验证人" prop="verifier" style="width: 22%">
            <a-input v-model="formData.verifier" placeholder="请输入" :disabled="showType=='detail'||(detailData&&detailData.status==3)"></a-input>
          </a-form-model-item>
          <a-form-model-item label="日期" prop="verificationtime" style="width: 22%;margin-left: -2.5%">
            <a-date-picker show-time date="选择时间" v-model="formData.verificationtime" :disabled="showType=='detail'||(detailData&&detailData.status==3)" @change="calcProcessTime(3)"/>
          </a-form-model-item>
          <a-form-model-item label="" prop="verification" style="margin-left: 5%;width: 100%" :wrapper-col="{ span: 22 }">
            <a-textarea v-model="formData.verification" placeholder="请项目填写执行及其验证情况" cols="150" rows="3" :disabled="showType=='detail'||(detailData&&detailData.status==3)"></a-textarea>
          </a-form-model-item>
          <a-form-model-item label="上传附件" prop="improveupload" style="width: 50%">
            <a-upload
                :disabled="showType=='detail'||(detailData&&detailData.status==3)"
                style="width: 220px"
                name="fileList"
                :headers="uploadHeaders"
                :data="supervise"
                action="/upload"
                :file-list="improveUploadList"
                @change="improveUploaded">
              <a-button :disabled="showType=='detail'||(detailData&&detailData.status==3)"> <a-icon type="upload" /> 点击上传文件 </a-button>
            </a-upload>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {mapGetters, mapState} from "vuex";
import {getUserList} from "A/xtpz";
import moment from "moment";
import {addImproveOrder, updateImproveOrder, updateSupervise} from "A/patrol";
export default {
  mixins: [deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  data() {
    return {
      modalVisible: false,
      monitorpointList:[],
      userList:[],
      improveUploadList:null,
      superviseUploadList:null,
      isDisabled:false,
      formData:{
        improveorderid:'',
        monitorpointnum:'',
        monitorpointname:'',
        improveordernum:'',
        orderstime:'',
        receiver:'',
        description:'',
        responsible:'',
        analysistime:'',
        causeanalysis:'',
        approver:'',
        improvetime:'',
        scheduledtime:'',
        improvemeasures:'',
        verifier:'',
        verificationtime:'',
        verification:'',
        superviseupload:'',
        improveupload:'',
        superviseid:'',
      },
      formDataRules:{},
      supervise:{
        folder: 'superviseFile',
        time: moment(new Date()).format("YYYYMMDDHHmmss"),
      },
    }
  },
  computed: {
    ...mapGetters(['uploadHeaders', 'uploadSupervise']),
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    }
  },
  watch: {
    visible(val) {
      if(val) {
        this.initDetail();
        this.modalVisible = true;
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetDatas();
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    initDetail() {
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
      getUserList({usertype:1,rolename:"项目管理员"}).then(res=>{
        if(res&&res.returncode=='0'){
          this.userList=res.item
        }
      })
      if(this.detailData && (this.showType == 'edit' || this.showType == 'detail')) {
        this.$nextTick(() => {
          for(let key in this.formData) {
            this.formData[key] = this.detailData[key] || '';
          }
          if (this.detailData.superviseupload) {
            let nameIndex = this.detailData.superviseupload.lastIndexOf('/');
            if (nameIndex < 0) {
              nameIndex = this.detailData.superviseupload.lastIndexOf('\\');
            }
            let fileName = this.detailData.superviseupload.substr(nameIndex + 1);
            this.superviseUploadList = [{
              uid: 'fileuid',
              name: fileName,
              status: 'done',
              url: this.detailData.superviseupload,
            }];
          }
          if (this.detailData.improveupload) {
            let nameIndex = this.detailData.improveupload.lastIndexOf('/');
            if (nameIndex < 0) {
              nameIndex = this.detailData.improveupload.lastIndexOf('\\');
            }
            let fileName = this.detailData.improveupload.substr(nameIndex + 1);
            this.improveUploadList = [{
              uid: 'fileuid',
              name: fileName,
              status: 'done',
              url: this.detailData.improveupload,
            }];
          }
        })
      }else{
        this.$nextTick(() => {
          for(let key in this.formData) {
            this.formData[key] = this.detailData[key] || '';
          }
          if (this.detailData.superviseupload) {
            let nameIndex = this.detailData.superviseupload.lastIndexOf('/');
            if (nameIndex < 0) {
              nameIndex = this.detailData.superviseupload.lastIndexOf('\\');
            }
            let fileName = this.detailData.superviseupload.substr(nameIndex + 1);
            this.superviseUploadList = [{
              uid: 'fileuid',
              name: fileName,
              status: 'done',
              url: this.detailData.superviseupload,
            }];
          }
        })
      }
    },
    resetDatas() {
      this.$refs.formData.resetFields();
      this.improveUploadList=null;
      this.superviseUploadList=null;
    },
    //启用提交按钮
    enable(){
      setTimeout(()=>{
        this.isDisabled=false
      },2000)
    },
    confirmOrder(){
      let orderstime = Date.parse(new Date(moment(this.formData.orderstime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"))) / 1000
      let analysistime = Date.parse(new Date(moment(this.formData.analysistime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"))) / 1000
      let improvetime = Date.parse(new Date(moment(this.formData.improvetime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"))) / 1000
      let verificationtime = Date.parse(new Date(moment(this.formData.verificationtime,"YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm:ss"))) / 1000
      if (((analysistime - orderstime) / 60).toFixed(2) < 0) {
        this.formData.analysistime=null
        this.$message.error("”原因分析时间”或“接单时间”错误，请复核")
      } else if (((improvetime - analysistime) / 60).toFixed(2) < 0) {
        this.formData.improvetime=null
        this.$message.error("”改进措施时间”或“原因分析时间”错误，请复核")
      } else if (((verificationtime - improvetime) / 60).toFixed(2) < 0) {
        this.formData.verificationtime=null
        this.$message.error("”执行验证时间”或“改进措施时间”错误，请复核")
      }else{
        if (this.showType == 'add') {
          this.formData.orderstime = this.formData.orderstime?moment(this.formData.orderstime).format("YYYYMMDDHHmmss"):null
          this.formData.analysistime = this.formData.analysistime?moment(this.formData.analysistime).format("YYYYMMDDHHmmss"):null
          this.formData.improvetime = this.formData.improvetime?moment(this.formData.improvetime).format("YYYYMMDDHHmmss"):null
          this.formData.scheduledtime = this.formData.scheduledtime?moment(this.formData.scheduledtime).format("YYYYMMDDHHmmss"):null
          this.formData.verificationtime = this.formData.verificationtime?moment(this.formData.verificationtime).format("YYYYMMDDHHmmss"):null
          this.isDisabled=true
          let params = {
            ...this.formData
          }
          addImproveOrder(params).then(res => {
            if (res && res.returncode == '0') {
              this.enable()
              this.formData.improveorderid = res.item[0].improveorderid
              this.modalVisible = false
              this.formData.issendorder = 1
              this.$refs.formData.resetFields();
              this.$message.success('操作成功');
              this.$emit('get-operation-result', res.item[0].improveorderid, 'success');
            } else {
              this.$message.error(res.errormsg || '操作失败');
            }
          })
        }else{
          if(!((this.formData.responsible&&this.formData.analysistime&&this.formData.causeanalysis)||(!this.formData.responsible&&!this.formData.analysistime&&!this.formData.causeanalysis))){
            this.$message.error("请填写完整原因分析中的内容，如有变更，可后期修改");
          }else if(!((this.formData.approver&&this.formData.improvetime&&this.formData.scheduledtime&&this.formData.improvemeasures)||(!this.formData.approver&&!this.formData.improvetime&&!this.formData.scheduledtime&&!this.formData.improvemeasures))){
            this.$message.error("请填写完整改进措施中的内容，如有变更，可后期修改");
          }else if(!((this.formData.verifier&&this.formData.verificationtime&&this.formData.verification)||(!this.formData.verifier&&!this.formData.verificationtime&&!this.formData.verification))){
            this.$message.error("请填写完整执行及其验证情况中的内容，如有变更，可后期修改");
          }else{
            this.formData.orderstime = this.formData.orderstime?moment(this.formData.orderstime).format("YYYYMMDDHHmmss"):null
            this.formData.analysistime = this.formData.analysistime?moment(this.formData.analysistime).format("YYYYMMDDHHmmss"):null
            this.formData.improvetime = this.formData.improvetime?moment(this.formData.improvetime).format("YYYYMMDDHHmmss"):null
            this.formData.scheduledtime = this.formData.scheduledtime?moment(this.formData.scheduledtime).format("YYYYMMDDHHmmss"):null
            this.formData.verificationtime = this.formData.verificationtime?moment(this.formData.verificationtime).format("YYYYMMDDHHmmss"):null
            this.isDisabled=true
            let params = {
              ...this.formData
            }
            if(this.formData.verifier&&this.formData.verificationtime&&this.formData.verification){
              params.status=2
            }
            updateImproveOrder(params).then(res => {
              if (res && res.returncode == '0') {
                this.enable()
                if(this.formData.verifier&&this.formData.verificationtime&&this.formData.verification){
                  updateSupervise({superviseid:this.formData.superviseid,recordstatus:2}).then(res=>{
                    if (res && res.returncode == '0') {
                      this.modalVisible = false
                      this.$refs.formData.resetFields();
                      this.$message.success('操作成功');
                      this.$emit('get-operation-result', 'success', '操作成功');
                    }
                  })
                }else{
                  this.modalVisible = false
                  this.$refs.formData.resetFields();
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                }
              } else {
                this.$message.error(res.errormsg || '操作失败');
              }
            })

          }
        }
      }
    },
    cancelOrder(){
      this.modalVisible=false
      this.formData.issendorder=1
      this.$refs.formData.resetFields();
      this.$emit('get-operation-result');
    },
    improveUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.name=this.supervise.time+"-"+file.name;
          file.response.urlPath="file/superviseFile/"+file.name
          file.url = file.response.urlPath;
          this.formData.improveupload = file.response.urlPath;
        }
        return file;
      });
      this.improveUploadList = fileList;
    },
    superviseUploaded(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.name=this.supervise.time+"-"+file.name;
          file.response.urlPath="file/superviseFile/"+file.name
          file.url = file.response.urlPath;
          this.formData.superviseupload = file.response.urlPath;
        }
        return file;
      });
      this.superviseUploadList = fileList;
    },
    calcProcessTime(type) {
      let orderstime = Date.parse(new Date(moment(this.formData.orderstime).format("YYYY-MM-DD HH:mm:ss"))) / 1000
      let analysistime = Date.parse(new Date(moment(this.formData.analysistime).format("YYYY-MM-DD HH:mm:ss"))) / 1000
      let improvetime = Date.parse(new Date(moment(this.formData.improvetime).format("YYYY-MM-DD HH:mm:ss"))) / 1000
      let verificationtime = Date.parse(new Date(moment(this.formData.verificationtime).format("YYYY-MM-DD HH:mm:ss"))) / 1000
      if(type==1){
        if (((analysistime - orderstime) / 60).toFixed(2) < 0||!this.formData.orderstime) {
          this.formData.analysistime=null
          this.$message.error("”原因分析时间”或“接单时间”错误，请复核")
        }else if (((improvetime - analysistime) / 60).toFixed(2) < 0||!this.formData.analysistime) {
          this.formData.improvetime=null
          this.$message.error("”改进措施时间”或“原因分析时间”错误，请复核")
        }
      }else if(type==2){
        if (((improvetime - analysistime) / 60).toFixed(2) < 0||!this.formData.analysistime) {
          this.formData.improvetime=null
          this.$message.error("”改进措施时间”或“原因分析时间”错误，请复核")
        }else if (((verificationtime - improvetime) / 60).toFixed(2) < 0||!this.formData.improvetime) {
          this.formData.verificationtime=null
          this.$message.error("”执行验证时间”或“改进措施时间”错误，请复核")
        }
      }else if(type==3){
        if (((verificationtime - improvetime) / 60).toFixed(2) < 0||!this.formData.improvetime) {
          this.formData.verificationtime=null
          this.$message.error("”执行验证时间”或“改进措施时间”错误，请复核")
        }else if(!this.formData.scheduledtime){
          this.$message.error("计划完成时间不能为空，请复核，如后期需要更新可再次更改")
        }
      }
    }
  }
}
</script>